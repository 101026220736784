<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE ITEM CATEGORY DATA</title>
    <section class="content-header">
      <h1>
        Edit Item Category Data
        <br />
        <h4>Please edit master item category data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Item Category Master</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Item Category Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Category </label>
                  <input
                    type="text"
                    v-model="kategoribarang"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idkategori"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Subcategory</label>
                  <input
                    type="text"
                    v-model="subkategori"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="catatan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <input
                    type="text"
                    v-model="tipe"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/kategori-barang">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "editmasterdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      kategoribarang: "",
      subkategori: "",
      catatan: "",
      tipe: "",
      idkategori: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/kategori_barang/getkategori_barangbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "kategori_barang/getkategori_barangbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.idkategori = resp.data.data.id;
          this.kategoribarang = resp.data.data.kategori;
          this.subkategori = resp.data.data.sub_kategori;
          this.catatan = resp.data.data.notes;
          this.tipe = resp.data.data.tipe;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kategori = this.kategoribarang;
      var subkategori = this.subkategori;
      var ctn = this.catatan;
      var tipe = this.tipe;
      var idx = this.idkategori;
      if (kategori == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Category can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipe == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (subkategori == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Subcategory can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ctn == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kategori: this.kategoribarang,
          sub_kategori: this.subkategori,
          notes: this.catatan,
          tipe: this.tipe,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/kategori_barang/updatekategori_barang/" + idx;
        const urlAPIUpdate =
          this.$apiurl + "kategori_barang/updatekategori_barang/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "kategoribarang" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data gagal",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "kategoribarang" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
